<template>
  <img :src="logoUrl" alt="Hub of Hope logo" />
</template>

<script setup lang="ts">
const props = defineProps({
  invert: Boolean
})

const logoUrl = computed(() => (props.invert ? '/static/hoh-logo-black.svg' : '/static/hoh-logo-white.svg'))
</script>
